import { useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { StaticImage } from 'gatsby-plugin-image';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Layout, Modal, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Cloud based management solution';
  const description = 'Enhance your operations and bring your community together with Njano for FREE';
  return <SEO title={title} description={description} location={location} />;
};

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Layout logo={false} dark>
      <div className="grid grid-cols-2 py-[5.25rem] px-4 lg:px-[8.75rem]">
        <div className="lg:col-span-1 flex-row items-start justify-center col-span-2 mb-16 space-y-12">
          <StaticImage
            className="mb-9"
            src="../images/logo-white.png"
            alt="Njano.org"
            formats={['auto', 'webp', 'avif']}
            placeholder="dominantColor"
            layout="fixed"
            width={328}
          />
          <p className="mr-12 mb-12 font-kanit text-5xl font-extralight leading-[3.75rem] text-[#E6E6E6]">
            Enhance your operations and bring your community together with Njano for{' '}
            <span className="text-secondary">FREE</span>*.
          </p>
          <Button
            onClick={() => {
              setShowModal(true);
            }}
            className="!block mx-auto lg:mx-0 lg:inline"
          >
            Get early access
          </Button>
        </div>
        <div className="lg:col-span-1 flex items-center justify-center col-span-2">
          <StaticImage
            className="max-w-lg"
            src="../images/header-home.png"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="dominantColor"
            layout="constrained"
          />
        </div>
      </div>
      <div className="pattern grid grid-cols-10 pt-[5.25rem] pb-[10.5rem] px-4 lg:px-[8.75rem]">
        <div className="lg:col-span-6 lg:py-0 lg:pr-12 lg:order-1 items-center order-2 col-span-10 py-8">
          <Parallax translateY={[0, 20]} speed={-10}>
            <StaticImage
              className="!block w-4/5 max-w-2xl mx-auto lg:inline lg:w-auto"
              src="../images/image-home.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="constrained"
            />
          </Parallax>
        </div>
        <div className="lg:col-span-4 lg:order-2 flex-col items-center justify-center order-1 col-span-10 space-y-8">
          <h2 className="font-heading lg:text-4xl xl:text-6xl text-3xl font-bold leading-normal text-left">
            All The Tools You Need In One Place!
          </h2>
          <p className="mb-8 font-kanit text-3xl font-extralight text-[#969696] lg:text-[2.5rem]">
            Fully customizable tools that will help you make the most of your time and focus on what really matters.
          </p>
          <p className="mb-8 font-kanit text-3xl font-extralight text-[#969696] lg:text-[2.5rem]">
            Frustration-free management is now a few clicks away!
          </p>
          <Button to="/features/" className="!block mx-auto lg:mx-0 lg:inline">
            View more
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-10 pb-[5.25rem] px-4 lg:px-[8.75rem]">
        <div className="lg:col-span-4 lg:py-0 lg:pr-12 items-center col-span-10 py-6 space-y-8">
          <h2 className="font-heading lg:text-6xl text-4xl font-bold leading-7 text-left">An all-in-one platform!</h2>
          <p className="mb-8 font-kanit text-3xl font-extralight text-[#969696] lg:text-[2.5rem]">
            Whether you run an incubator, a coworking space or an accelerator, Njano is your go-to platform!
          </p>
        </div>
        <div className="lg:col-span-4 lg:col-start-7 col-span-10">
          <div className="grid grid-cols-2">
            <div className="xl:space-y-2 lg:col-span-1 xl:my-0 xl:items-center xl:justify-between flex flex-col items-center justify-center col-span-2 my-8">
              <StaticImage
                src="../images/coworking.png"
                alt=""
                formats={['auto', 'webp', 'avif']}
                placeholder="dominantColor"
                layout="constrained"
                height={120}
              />
              <h3 className="font-heading text-[1.8rem] xl:text-[2.5rem] font-bold leading-tight xl:leading-normal text-center lg:h-[19rem] lg:flex lg:justify-center lg:items-center">
                Coworking spaces
              </h3>
              <Button to="/coworking-spaces/">View more</Button>
            </div>
            <div className="xl:space-y-2 lg:col-span-1 lg:my-0 xl:items-center xl:justify-between flex flex-col items-center justify-center col-span-2 my-8">
              <StaticImage
                src="../images/incubator.png"
                alt=""
                formats={['auto', 'webp', 'avif']}
                placeholder="dominantColor"
                layout="constrained"
                height={120}
              />
              <h3 className="font-heading text-[1.8rem] xl:text-[2.5rem] font-bold leading-tight xl:leading-normal text-center lg:h-[19rem] lg:flex lg:justify-center lg:items-center">
                Incubators
              </h3>
              <Button to="/incubators/">View more</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 grid grid-cols-3 content-around py-[5.25rem] px-4 lg:px-[8.75rem]">
        <div className="xl:col-span-1 xl:mb-0 xl:justify-start flex items-center justify-center col-span-3 mb-16">
          <h2 className="font-heading xl:text-left 2xl:text-5xl text-4xl font-bold text-center">They trusted us:</h2>
        </div>
        <div className="col-span-3 flex flex-col items-center justify-center space-y-12 lg:flex-row lg:space-y-0 lg:space-x-[6.75rem] xl:col-span-2">
          <Swiper
            slidesPerView="auto"
            autoplay
            breakpoints={{
              480: { width: 480, slidesPerView: 2, spaceBetween: 20 },
              760: { width: 760, slidesPerView: 3, spaceBetween: 40 },
              1536: { width: 1536, slidesPerView: 5, spaceBetween: 40 }
            }}
            loop
            speed={3000}
            centerInsufficientSlides
            centeredSlides
            spaceBetween={25}
            modules={[Autoplay]}
            className="!w-full overflow-hidden h-40"
          >
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://icealex.com" title="Icealex" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/icealex.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={275}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://afkar.co" title="Afkar Incubator" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/afkar.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={96}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://cogite.tn" title="Cogite Coworking Space" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/cogite.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={120}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://hivos.org" title="Hivos" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/hivos.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={184}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://thedot.tn" title="The Dot" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/thedot.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={104}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://hub.aiivon.com/" title="Aiivon Innovation Hub" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/aiivon.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={184}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a
                href="https://yerevan.impacthub.net/"
                title="Impact Hub Yerevan"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StaticImage
                  src="../images/impact-hub.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={240}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://ileycom.com/" title="Iley'com" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/ileycom.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={250}
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="h-36 flex items-center justify-center mx-auto">
              <a href="https://werebase.com/" title="Werebase" target="_blank" rel="noopener noreferrer">
                <StaticImage
                  src="../images/werebase.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  placeholder="dominantColor"
                  layout="constrained"
                  width={250}
                />
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="grid grid-cols-4 bg-white py-[5.25rem] px-4 lg:px-[8.75rem]">
        <div className="col-span-4 mb-8">
          <h2 className="font-heading text-darkGray lg:text-6xl text-4xl font-bold leading-normal text-center">
            Latest news
          </h2>
        </div>
        <div className="lg:col-span-2 2xl:col-span-1 2xl:my-0 flex flex-col items-center justify-center col-span-4 m-8 shadow-md">
          <StaticImage
            src="../images/article1.jpg"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="blurred"
            layout="constrained"
          />
          <div className="relative px-2 py-4">
            <h4 className="text-md font-heading text-darkGray line-clamp-1 mb-4 font-bold text-left">
              Discover the Njano Platform !
            </h4>
            <p className="mb-12 overflow-hidden font-kanit text-lg font-extralight text-[#969696] line-clamp-2">
              Njano is a cloud-based flexible office management solution designed to manage
            </p>
            <Button className="-bottom-8 lg:left-8 absolute left-0" to="/article-1/">
              View more
            </Button>
          </div>
        </div>
        <div className="lg:col-span-2 2xl:col-span-1 2xl:my-0 flex flex-col items-center justify-center col-span-4 m-8 shadow-md">
          <StaticImage
            src="../images/article2.jpg"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="blurred"
            layout="constrained"
          />
          <div className="relative px-2 py-4">
            <h4 className="text-md font-heading text-darkGray line-clamp-1 mb-4 font-bold text-left">
              Introducing The New Version Of Njano !
            </h4>
            <p className="mb-12 overflow-hidden font-kanit text-lg font-extralight text-[#969696] line-clamp-2">
              We are excited to announce that the new version of Njano is now online and available to...
            </p>
            <Button className="-bottom-8 lg:left-8 absolute left-0" to="/article-2/">
              View more
            </Button>
          </div>
        </div>
        <div className="lg:col-span-2 2xl:col-span-1 2xl:my-0 flex flex-col items-center justify-center col-span-4 m-8 shadow-md">
          <StaticImage
            src="../images/article3.jpg"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="blurred"
            layout="constrained"
          />
          <div className="relative px-2 py-4">
            <h4 className="text-md font-heading text-darkGray line-clamp-1 mb-4 font-bold text-left">
              Njano, Linking the African Crossroads Network
            </h4>
            <p className="mb-12 overflow-hidden font-kanit text-lg font-extralight text-[#969696] line-clamp-2">
              Njano is a cloud-based flexible office management solution designed to manage workplaces.
            </p>
            <Button className="-bottom-8 lg:left-8 absolute left-0" to="/article-3/">
              View more
            </Button>
          </div>
        </div>
        <div className="lg:col-span-2 2xl:col-span-1 2xl:my-0 flex flex-col items-center justify-center col-span-4 m-8 shadow-md">
          <StaticImage
            src="../images/article4.jpg"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="blurred"
            layout="constrained"
          />
          <div className="relative px-2 py-4">
            <h4 className="text-md font-heading text-darkGray line-clamp-1 mb-4 font-bold text-left">
              March Newsletter
            </h4>
            <p className="mb-12 overflow-hidden font-kanit text-lg font-extralight text-[#969696] line-clamp-2">
              With the end of winter, we hope March has been your great month to “spring” into effective...
            </p>
            <Button className="-bottom-8 lg:left-8 absolute left-0" to="/article-4/">
              View more
            </Button>
          </div>
        </div>
      </div>
      {showModal ? <Modal open={showModal} setOpen={setShowModal} /> : null}
    </Layout>
  );
};

export default IndexPage;
